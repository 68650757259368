import React, { useState, useEffect} from 'react'
import axios from 'axios'

const DataFetching = () => {
    const [doramalist, setPosts] = useState([])

    useEffect(()=>{
        axios.get('https://api.ulamag.by/api/v1/doramalist/')
        .then(res =>{
            console.log(res);
            setPosts(res.data)
        })

        .catch(err =>{
            console.log(err);
        })
    }, [])
  return (
    <div>
        <ul>
            {
                doramalist.map(post => <li key={post.id}>{post.doramaName}</li>)
            }
        </ul>
    </div>
  )
}

export default DataFetching