import React from 'react'
import { Homes } from '../components/homes/Homes'




export const HomePages = () => {
  return (
  <>
  <Homes />
  </>
  )
}
