// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Header } from './components/header/Header';
import { HomePages } from './home/HomePages';
import DataFetching from './DataFetching';


function App() {
   return (
   <> 
    <Router>
        <Header />
        <Switch>
            <Route exact path='/' component={HomePages} />
        </Switch>
    </Router>
    <DataFetching/>
    </>
 )
} 


export default App;
