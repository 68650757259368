import React, { useState } from 'react'
import './header.css'
import { Link } from 'react-router-dom'
// import logo from '../../logo.png';


export const Header = () => {
  const [Mobile, setMobile] = useState(false)
  return (
    <>
      <header>
        <div className='container flexSB'>
          <nav className='flexSB'>
            <div className='logo'>
              {/* <img src={logo} alt='logo'></img> */}
            </div>
            <ul className={Mobile ? 'navMenu-list':'flexSB'} onClick={()=>setMobile(false)}>
              <Link to='/'>Главная</Link>
              <Link to='/dorama'>Дорамы</Link>
              <Link to='/forum'>Форум</Link>
              <Link to='/registration'>Регистрация</Link>
              <Link to='/open'>Войти</Link>
            </ul>
            <button className='toggle' onClick={()=>setMobile(!Mobile)}>{Mobile ? <i className='fa fa-bars'></i> : <i className='fa fa-bars'></i>}</button>
          </nav>
          <div className='account flesSB'>
            <i className='fa fa-search'></i>
            <i className='fa fa-bell'></i>
            <i className='fa fa-user'></i>
          </div>
        </div>
      </header>
    </>
  )
}
