import React, {useState}  from 'react'
import { homeData } from '../../dummyData'


export const Homes = () => {
    const [data, setItems] = useState(homeData)
  return (
    <>
    {/* <section className='home'>
        <Home items={items} />
    </section>    */}
    <div className='margin'></div> 
    </>
  )
}
